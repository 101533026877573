import { Component, OnInit } from '@angular/core';

import {Router} from '@angular/router';
import {LoginService} from '../services/login.service';
import {ToastService} from '../services/toast.service';

import * as $ from 'jquery';

@Component({
	selector: 'app-login-page',
	templateUrl: './login-page.component.html',
	styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent implements OnInit {

	// Form variables
	mail = "";
	password = "";


	constructor(
		private router: Router,
		private LoginService: LoginService,
		public toastService: ToastService
	) {}

	ngOnInit() {
		var _this = this ;
		this.LoginService.isLogged().onload = function() : void {
			if (this.status >= 200 && this.status < 400) {
				let response = JSON.parse(this.response);

				// If the user is already logged, redirects toward the main view
				if (response.data){
					_this.router.navigateByUrl('/board');
				}
			} else {
				// We reached our target server, but it returned an error
				_this.showErrorToast("Erreur dans la vérification de connection", "Erreur dans la réponse du serveur");
			}
		};
	}

	onSubmit() {
		var _this = this ;
		this.LoginService.sendLogin(this.mail, this.password).onload = function() : void {
			if (this.status >= 200 && this.status < 400) {
				let response = JSON.parse(this.response);
				if (response.success){
					_this.router.navigateByUrl('/board');
				} else {
					_this.showErrorToast("Connection échouée",response.error.message);
				}

			} else {
				// We reached our target server, but it returned an error
				_this.showErrorToast("Connection échouée","Erreur dans la réponse du serveur");
			}
		};
	}

	//--- Toast notifications ---

	showSuccessToast(headerMsg: string, msg: string) {
		this.toastService.show(msg, {
			classname: 'bg-success text-light',
			delay: 4000,
			autohide: true,
			headertext: headerMsg
		});
	}

	showErrorToast(headerMsg: string, msg: string) {
		this.toastService.show(msg, {
			classname: 'bg-danger text-light',
			delay: 4000,
			autohide: true,
			headertext: headerMsg
		});
	}

}
