import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
//import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Label } from 'ng2-charts';

import {Router} from '@angular/router';
import {ReportService} from '../services/report.service';
import {Filters} from '../services/report.service';
import {MainViewManagerService} from '../managers/main-view-manager.service';
import {LoginService} from '../services/login.service';
import {ToastService} from '../services/toast.service';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ViewChild } from '@angular/core'



declare var require: any;

@Component({
	selector: 'app-report-graph',
	templateUrl: './report-graph.component.html',
	styleUrls: ['./report-graph.component.css']
})
export class ReportGraphComponent implements OnInit {

	onReport = false;

	@ViewChild('longContent', {static: false}) longContent;

	constructor(
		private router: Router,
		private mng: MainViewManagerService,
		private ReportService: ReportService,
		private LoginService: LoginService,
		private modalService: NgbModal,
		public toastService: ToastService
	) { }

	ngOnInit() {
		this.mng.onChartClick(this.openScrollableContent.bind(this));
	}

	// Opens the customer list modal
	openScrollableContent(){ //templateRef) {
		this.modalService.open(this.longContent, { scrollable: true, size: 'lg' });	//TODO: change the template reference the templateRef
	}
	
	downloadPortableReport(report){
		if(this.onReport === true){
			return;
		}
		this.onReport = true;
		this.ReportService.requestReport(report, null, "portable");
		this.onReport = false;
	}

	// Send a logout request to the server, then redirects to the login page
	logout() {
		var _this = this ;
		this.LoginService.sendLogout().onload = function() : void {
			if (this.status >= 200 && this.status < 400) {
				let response = JSON.parse(this.response);
				
				if (response.success){
					_this.showSuccessToast("Déconnexion","Déconnecté avec succès");
					_this.router.navigateByUrl('/');
					_this.mng.resetVariables();			// Reset all important variables to start from a clean page again
				} else {
					let message = "Réponse négative du serveur";
					if(response.error !== undefined && response.error.message !== undefined){
						message = response.error.message;
					}
					
					// Session expiré ou autre
					if(message.includes("context")){
						_this.router.navigateByUrl('/');
						_this.mng.resetVariables();			// Reset all important variables to start from a clean page again
					}else{
						_this.showErrorToast("Erreur lors de la déconnexion", message);
					}
					
				}

			} else {
				// We reached our target server, but it returned an error
				_this.showErrorToast("Déconnexion échouée","Erreur dans la réponse du serveur");
			}
		};
	}
	

	//--- Toast notifications ---
	showSuccessToast(headerMsg: string, msg: string) {
		this.toastService.show(msg, {
			classname: 'bg-success text-light',
			delay: 4000 ,
			autohide: true,
			headertext: headerMsg
		});
	}

	showErrorToast(headerMsg: string, msg: string) {
		this.toastService.show(msg, {
			classname: 'bg-danger text-light',
			delay: 4000,
			autohide: true,
			headertext: headerMsg
		});
	}

}