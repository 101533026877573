import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { HttpHeaders } from '@angular/common/http';

import {ConfigService} from './config.service';


const httpOptions = {
	headers: new HttpHeaders({
		'Content-Type': 'application/json',
	'charset': 'utf-8',
	'withCredentials' : "true"
	})
};

export interface JsonResponse {
	success: boolean;
}

@Injectable({
	providedIn: 'root'
})
export class LoginService {

	serverUrl = this.configService.configData.serverUrl;

	constructor(
		private http: HttpClient,
		private configService:ConfigService
	) {}
	
	
	// Return true if the user/password combination is correct, false otherwise
	sendLogin (login: string, password: string): any {
		const data = {
			"action" : "core.user.login",
			"parameters" :
			{
				"credential" : {
					"login" : login,
					"password" : password
				}
			}
		};
		var request = new XMLHttpRequest();
		request.open('POST', this.serverUrl, true);
		request.withCredentials = true;
		request.send(JSON.stringify(data));
		return request;
	}

	// Logout the user. 'success' always returns true.
	sendLogout (): any {
		const data = {
			"action" : "core.user.logout",
			"parameters" : {}
		};
		var request = new XMLHttpRequest();
		request.open('POST', this.serverUrl, true);
		request.withCredentials = true;
		request.send(JSON.stringify(data));
		return request;
	}

	// Check if the user is logged in
	isLogged (): any {
		const data = {
			"action" : "core.user.logged",
			"parameters" : {}
		};
		var request = new XMLHttpRequest();
		request.open('POST', this.serverUrl, true);
		request.withCredentials = true;
		request.send(JSON.stringify(data));
		return request;
	}

}
