import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from "@angular/core";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { MatIconRegistry, MatIconModule } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

import { FilterBoxComponent } from './filter-box/filter-box.component';
import { ViewSelectorComponent } from './view-selector/view-selector.component';
import { ReportGraphComponent } from './report-graph/report-graph.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginPageComponent } from './login-page/login-page.component';
import { MainViewComponent } from './main-view/main-view.component';

import { ChartsModule } from 'ng2-charts';

import {ConfigService} from './services/config.service';
import { ToastComponent } from './toast/toast.component';

@NgModule({
	declarations: [
		AppComponent,
		FilterBoxComponent,
		ViewSelectorComponent,
		ReportGraphComponent,
		LoginPageComponent,
		MainViewComponent,
		ToastComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		FormsModule,
		NgbModule,
		HttpClientModule,
		MatIconModule,
		ChartsModule
	],
	providers: [
		[
			{
				provide: APP_INITIALIZER,
				multi: true,
				deps: [ConfigService],
				useFactory: (configService: ConfigService) => {
					return () => {
						return configService.loadConfigData();
					};
				}
			}
		]
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer){
		matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg')); // https://dev.materialdesignicons.com/getting-started/angular
	}
}
