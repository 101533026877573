
import {Component, Injectable} from '@angular/core';

import {NgbDatepickerConfig, NgbDateStruct, NgbCalendar, NgbDate, NgbDatepickerI18n} from '@ng-bootstrap/ng-bootstrap';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

import {Router} from '@angular/router';
import {LoginService} from '../services/login.service';
import {ReportService} from '../services/report.service';
import {Filters} from '../services/report.service';
import {ToastService} from '../services/toast.service';
import {MainViewManagerService} from '../managers/main-view-manager.service'

//Chartjs and ng2-charts
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
//import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Label } from 'ng2-charts';


// --- French translations on the datepicker ---

const I18N_VALUES = {
	'fr': {
		weekdays: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
		months: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc'],
	}
	// other languages you would support
};

// Define a service holding the language
@Injectable()
export class I18n {
	language = 'fr';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
	constructor(private _i18n: I18n) {
		super();
	}
	getWeekdayShortName(weekday: number): string {
		return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
	}
	getMonthShortName(month: number): string {
		return I18N_VALUES[this._i18n.language].months[month - 1];
	}
	getMonthFullName(month: number): string {
		return this.getMonthShortName(month);
	}
	getDayAriaLabel(date: NgbDateStruct): string {
		return `${date.day}-${date.month}-${date.year}`;
	}
}


@Component({
	selector: 'app-filter-box',
	templateUrl: './filter-box.component.html',
	styleUrls: ['./filter-box.component.css'],
	styles: [`
		.custom-day {
			text-align: center;
			padding: 0.185rem 0.25rem;
			display: inline-block;
			height: 2rem;
			width: 2rem;
		}
		.custom-day.focused {
			background-color: #e6e6e6;
		}
		.custom-day.range, .custom-day:hover {
			background-color: rgb(2, 117, 216);
			color: white;
		}
		.custom-day.faded {
			background-color: rgba(2, 117, 216, 0.5);
		}
	`],
	providers: [NgbDatepickerConfig, I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}]
})

export class FilterBoxComponent {

	isCustomerArborescenceDisplayed = true;
	isCalendarDisplayed = false;

	isStep1LitUp = false;	// State of the number icon in the first header

	constructor(
		private router: Router,
		private LoginService: LoginService,
		private ReportService: ReportService,
		public toastService: ToastService,
		private mng: MainViewManagerService,
		config: NgbDatepickerConfig,
		calendar: NgbCalendar,
		private modalService: NgbModal
	) {}
	
	toResponse(str:string): any{
		if (str === "") {
			this.router.navigateByUrl('/');
			return null;
		}
		return JSON.parse(str);
	}

	ngOnInit() {
		
		var _this = this ;
		this.LoginService.isLogged().onload = function() : void {
			if (this.status >= 200 && this.status < 400) {
				
				let response = _this.toResponse(this.response);
				if(response === null){
					return;
				}

				if (response.data){
					_this.getPerimeter();
					_this.getUnitList();
				}
				else {
					_this.showErrorToast("Vous n'êtes pas connectés", "Redirection vers la page de connection");
					_this.router.navigateByUrl('/');
				}
			} else {
				// We reached our target server, but it returned an error
				_this.showErrorToast("Erreur dans la réponse du serveur lors de la vérification de connection", "Redirection vers la page de connection");
				_this.router.navigateByUrl('/');
			}
		};
	}

	//--- Hide/Display panels ---
	displayClientArborescence(){
		this.isCustomerArborescenceDisplayed = !this.isCustomerArborescenceDisplayed;
		this.isCalendarDisplayed = false;
	}

	// If hosts are selected, display the calendar AND gets the max period depending on the hosts
	displayCalendar(){
		if (this.isStep1LitUp){
			this.isCustomerArborescenceDisplayed = false;
			this.isCalendarDisplayed = !this.isCalendarDisplayed;

			this.getMaxPeriod();
		}
	}


	//--- Datepicker ---

	onDateSelection(date: NgbDate) {
		if (!this.mng.fromDate && !this.mng.toDate) {
			this.mng.fromDate = date;
		} else if (this.mng.fromDate && !this.mng.toDate && (date.after(this.mng.fromDate) || date.equals(this.mng.fromDate)) ) {
			this.mng.toDate = date;
		} else {
			this.mng.toDate = null;
			this.mng.fromDate = date;
		}
	}

	isHovered(date: NgbDate) {
		return this.mng.fromDate && !this.mng.toDate && this.mng.hoveredDate && date.after(this.mng.fromDate) && date.before(this.mng.hoveredDate);
	}

	isInside(date: NgbDate) {
		return date.after(this.mng.fromDate) && date.before(this.mng.toDate);
	}

	isRange(date: NgbDate) {
		return date.equals(this.mng.fromDate) || date.equals(this.mng.toDate) || this.isInside(date) || this.isHovered(date);
	}


	//--- Date converters ---

	// Convert NgbDate to Date
	ngbDateToDate(ngbDate: NgbDate) {
		return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
	}

	// Convert Date to NgbDate
	dateToNgbDate(date: Date) {
		return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
	}

	// Convert NgbDate to a displayable string version
	ngbDateToString(ngbDate: NgbDate) {
		let displayedDay = ngbDate.day;
		let displayedMonth = ngbDate.month;
		let zeroDay = "";
		let zeroMonth = "";
		if (displayedDay < 10) zeroDay = "0";
		if (displayedMonth < 10) zeroMonth = "0";
		return zeroDay+displayedDay+"/"+zeroMonth+displayedMonth+"/"+ngbDate.year;
	}


	//--- Customer arborescence ---

	// Checks/uncheck the clicked host
	toggleHost(customer: any, host: any){
		host.toggled = !host.toggled;

		// If and only if all hosts are toggled, display a marked indicator. If a few are toggled, display an intermediate indicator. Else, show an empty indicator
		let areAllHostsToggledOn = true;
		let areAllHostsToggledOff = true;
		for (let host of customer.hosts){
			if (!host.toggled) {
				areAllHostsToggledOn = false;
			}
			else {
				areAllHostsToggledOff = false;
			}
		}
		if(areAllHostsToggledOn){
			customer.toggled = 'marked';
		}
		else {
			if (areAllHostsToggledOff) {
				customer.toggled = 'unmarked';
			}
			else {
				customer.toggled = 'intermediate';
			}
		}

		if (this.getCheckedHostArray().length == 0){
			this.isStep1LitUp = false;
		}
		else {
			this.isStep1LitUp = true;
		}
	}

	// Checks/uncheck the customer and all its hosts (true means checked)
	toggleAllHostFromCustomer(customer: any, newState: boolean){
		customer.toggled = newState ? 'marked' : 'unmarked';

		for (let host of customer.hosts){
			host.toggled = newState;
		}
		if (this.getCheckedHostArray().length == 0){
			this.isStep1LitUp = false;
		}
		else {
			this.isStep1LitUp = true;
		}
	}

	toggleEveryCustomer(newState: boolean){
		for (let customer of this.mng.customers){
			this.toggleAllHostFromCustomer(customer, newState);
		}
	}

	toggleCollapseOfEveryHosts(newState: boolean){
		for (let customer of this.mng.customers){
			customer.collapsed = newState;
		}
	}

	// Return the list of the codes of all checked hosts in the arborescence
	getCheckedHostArray(): number[] {
		let checkedHosts = [];
		for (let customer of this.mng.customers){
			for (let host of customer.hosts){
				if (host.toggled){
					checkedHosts.push(host.code);
				}
			}
		}
		return checkedHosts;
	}


	//--- Server requests ---

	// Get the list of customers and hosts
	getPerimeter() : void{
		var _this = this ;
		this.ReportService.requestPerimeter().onload = function() : void {
			if (this.status >= 200 && this.status < 400) {
				
				let response = _this.toResponse(this.response);
				if(response === null){
					return;
				}
				
				let responseCustomers = response.data.customers;
				let responseHosts = response.data.hosts;

				_this.mng.customers = [];
				
				for (let customerObj of responseCustomers){
					customerObj.toggled = 'unmarked';
					customerObj.collapsed = true;
					customerObj.hosts = [];
					_this.mng.customers.push(customerObj);
				}

				for (let hostObj of responseHosts){
					let cleanHostObj = {
						"code": hostObj.code,
						"name": hostObj.name
					};

					// Add the host to the matching customer
					let matchingCustomer = _this.mng.customers.find(customerElem => customerElem.code === hostObj.customer.code);
					matchingCustomer.hosts.push(cleanHostObj);
				}

			} else {
				// We reached our target server, but it returned an error
				_this.showErrorToast("Erreur dans la réponse du serveur", "Impossible de récupérer la liste de clients/hôtes");
			}
		};
	}

	// Get the minimum and maximum date of the request
	getMaxPeriod() : void{
		var _this = this ;
		let checkedHostArray = _this.getCheckedHostArray();
		if (checkedHostArray.length > 0){

			_this.mng.waitingForMaxPeriodServerResponse = true;		// Displays the spinner until the server responds

			this.ReportService.requestMaxPeriod(checkedHostArray).onload = function() : void {
				if (this.status >= 200 && this.status < 400) {
					
					let response = _this.toResponse(this.response);
					if(response === null){
						return;
					}

					if (response.success){
						// Fetch the new minimum/maximum selectable dates from the server and adjust the current selection if it is out of bounds
						let newMinDate = _this.dateToNgbDate(new Date(response.data.min));
						if(_this.mng.fromDate && _this.mng.fromDate.before(newMinDate)){
							_this.mng.fromDate = newMinDate;
						}
						if(_this.mng.toDate && _this.mng.toDate.before(newMinDate)){
							_this.mng.toDate = newMinDate;
						}

						let newMaxDate = _this.dateToNgbDate(new Date(response.data.end));
						if(_this.mng.toDate && _this.mng.toDate.after(newMaxDate)){
							_this.mng.toDate = newMaxDate;
						}
						if(_this.mng.fromDate && _this.mng.fromDate.after(newMaxDate)){
							_this.mng.fromDate = newMaxDate;
						}

						// Change the new minimum/maximum selectable in the calendar (with a timeout to avoid an error caused by race condition)
						_this.mng.maxSelectableNgbDate = newMaxDate;
						setTimeout(() => {
							_this.mng.minSelectableNgbDate = newMinDate;
						}, 0) ;

						//_this.showSuccessToast("Maximum allowed period successfully returned from server", "Calendar limits updated");
						_this.mng.waitingForMaxPeriodServerResponse = false;	// Hides the spinner
					}
					else {
						_this.showErrorToast("Erreur dans la réponse du serveur lors de la récuperation de période max", response.error.message);
						_this.mng.waitingForMaxPeriodServerResponse = false;	// Hides the spinner
					}
				} else {
					// We reached our target server, but it returned an error
					_this.showErrorToast("Erreur dans la réponse du serveur", "Impossible de récuperer la période max");
					_this.mng.waitingForMaxPeriodServerResponse = false;	// Hides the spinner
				}
			};

		}
		else{
			this.showErrorToast("Requête de période max incomplète", "Aucun client/hôte sélectionné");
		}
	}

	// Get the list of time units available for the display of the reporting data
	getUnitList() : void{
		var _this = this ;
		this.ReportService.requestUnitList().onload = function() : void {
			if (this.status >= 200 && this.status < 400) {
				
				let response = _this.toResponse(this.response);
				if(response === null){
					return;
				}
				
				_this.mng.timePeriodList = response.data;
			} else {
				// We reached our target server, but it returned an error
				_this.showErrorToast("Erreur dans la réponse du serveur", "Impossible de récuperer la liste de pas");
			}
		};
	}

	// Get the reporting data for the chosen report type
	getReportingData (report: string): void {
		var _this = this ;

		let checkedHostArray = this.getCheckedHostArray();
		if (checkedHostArray.length > 0 && _this.mng.fromDate && _this.mng.toDate){

			_this.mng.waitingForReportDataServerResponse = true;		// Displays the spinner until the server responds

			let startDate = _this.ngbDateToDate(_this.mng.fromDate);
			let endDate = _this.ngbDateToDate(_this.mng.toDate);
			let startOffset = startDate.getTimezoneOffset();
			let endOffset = endDate.getTimezoneOffset();
			let UTCStartDate = startDate.getTime() - startOffset * 60 * 1000;			// Convert all dates to UTC (the offset is in minutes, but the epoch time is in milliseconds)
			let UTCEndDate = endDate.getTime() - endOffset * 60 * 1000;
			
			
			let filters : Filters = {
				hosts : checkedHostArray,
				start : UTCStartDate,
				end : UTCEndDate,
				unit : _this.mng.timePeriod
			};
			
			this.ReportService.requestReport(report, filters).onload = function() : void {
			//this.ReportService.requestReportingData(report, checkedHostArray, UTCStartDate, UTCEndDate, _this.mng.timePeriod).onload = function() : void {
				if (this.status >= 200 && this.status < 400) {
					
					let response = _this.toResponse(this.response);
					if(response === null){
						return;
					}
					if (response.success){

						_this.mng.reportingCustomerList = []; 			//Resets the customer list
						_this.mng.barChartLabels = [];
						_this.mng.barChartData = [
							{ 
								data: [],
								label: 'Comptes utilisateurs',
								backgroundColor: '#efeff1',
											borderColor: '#64f3',
											hoverBackgroundColor: '#d1d1f0',
											hoverBorderColor: '#c29fd1',
											borderWidth: 3
										},
							{
								data: [],
								label: 'Comptes connectés',
								backgroundColor: '#daf0da',
											borderColor: '#64f3',
											hoverBackgroundColor: '#34a853',
											hoverBorderColor: '#c29fd1',
											borderWidth: 3
										},
							{
								data: [],
								label: 'Comptes désactivés',
								backgroundColor: '#b0b0b3',
											borderColor: '#64f3',
											hoverBackgroundColor: '#4d4d51',
											hoverBorderColor: '#c29fd1',
											borderWidth: 3
							}
						];
						for (let dataObj of response.data.times){
							_this.mng.barChartLabels.push(dataObj.key);								// Get the current horizontal axis label
							_this.mng.barChartData[0].data.push(dataObj.user.created.total);
							_this.mng.barChartData[1].data.push(dataObj.user.connected.total);
							_this.mng.barChartData[2].data.push(dataObj.user.deleted.total);
							_this.mng.reportingCustomerList.push(dataObj.user.connected.users);		// Get the list of all customers connected on this data point
						}

						//--------------------
						//TODO: SAVE ALL DATA OF dataObj.user.connected.users if given, to display later on click of that bar on the chart
						//--------------------
						_this.mng.scope = response.data.scope;
						_this.mng.displayedHostCount = checkedHostArray.length;						// Number of checked hosts to display in the graph title
						_this.mng.displayedStartDate = _this.ngbDateToString(_this.mng.fromDate);	// String version of min date, to display in the graph title
						_this.mng.displayedEndDate = _this.ngbDateToString(_this.mng.toDate);		// String version of min date, to display in the graph title

						_this.mng.displayReportGraph = true; 										// Once all variables are set, display the graph
						_this.mng.waitingForReportDataServerResponse = false;						// Hides the spinner
					}
					else {
						_this.showErrorToast("Erreur dans la réponse du serveur lors de la récupération du reporting", response.error.message);
						_this.mng.waitingForReportDataServerResponse = false;						// Hides the spinner
					}


				} else {
					// We reached our target server, but it returned an error
					_this.showErrorToast("Erreur dans la réponse du serveur", "Impossible de récuperer les données du reporting");
					_this.mng.waitingForReportDataServerResponse = false;							// Hides the spinner
				}
			};

		}
		else {
			this.showErrorToast("Erreur dans la réponse du serveur", "Impossible de récuperer les données du reporting");
		}

	}


	//--- Toast notifications ---

	showStandardToast(msg: string) {
		this.toastService.show(msg, {
			delay: 4000,
			autohide: true
		});
	}

	showSuccessToast(headerMsg: string, msg: string) {
		this.toastService.show(msg, {
			classname: 'bg-success text-light',
			delay: 4000 ,
			autohide: true,
			headertext: headerMsg
		});
	}
	showErrorToast(headerMsg: string, msg: string) {
		this.toastService.show(msg, {
			classname: 'bg-danger text-light',
			delay: 4000 ,
			autohide: true,
			headertext: headerMsg
		});
	}

	/*
	<ng-template #customTpl>
		<i>This is</i> a <u>custom</u> template <b>toast message</b>
	</ng-template>
	<button (click)="showCustomToast(customTpl)>SHOW CUSTOM TOAST</button>"
	*/
	showCustomToast(customTpl) {
		this.toastService.show(customTpl, {
			classname: 'bg-info text-light',
			delay: 4000,
			autohide: true
		});
	}
}
