import { Injectable, Component, ViewEncapsulation, TemplateRef } from '@angular/core';

import {ReportService} from '../services/report.service';
import {NgbDatepickerConfig, NgbDateStruct, NgbCalendar, NgbDate} from '@ng-bootstrap/ng-bootstrap';

//Chartjs and ng2-charts
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
//import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Label } from 'ng2-charts';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Injectable({
	providedIn: 'root'
})
export class MainViewManagerService {

	// Allows "sendModalDataToReportGraph" to be called in the service, which is bound to the modal display function of the report graph component
	private sendModalDataToReportGraph: () => void;
	onChartClick(fn: () => void) {
	    this.sendModalDataToReportGraph = fn;
	}
	
	//--- Filter variables ---
	hoveredDate: NgbDate;

	fromDate: NgbDate;
	toDate: NgbDate;

	timePeriod: string = "YEAR";
	timePeriodList = [];

	closeResult: string;

	customers = [];

	waitingForMaxPeriodServerResponse = false;				// Displays the spinner and hides the calendar if yes, until the server responds
	waitingForReportDataServerResponse = false;				// Displays the spinner and hides the report area if yes, until the server responds

	minSelectableNgbDate = new NgbDate(2000, 1, 12);
	maxSelectableNgbDate = new NgbDate(2000, 1, 14);

	displayedHostCount = 0;									// Number of checked hosts to display in the graph title
	displayedStartDate = "";								// String version of the starting date, to display in the graph title
	displayedEndDate = "";									// String version of the ending date, to display in the graph title
	scope = 0;
	
	reportingCustomerList = [];								// List of all customers connected on this data point
	displayedReportingCustomerList = [];					// The list of the first 100 customers, displayed in the modal

	//--- Reporting variables ---
	public displayReportGraph = false;
	public barChartOptions: ChartOptions = {
		onClick : (evt, array) => {

			let clickedDataIndex = (array[0] ? array[0]['_index'] : -1);

			if (clickedDataIndex != -1){
				this.displayedReportingCustomerList = this.reportingCustomerList[clickedDataIndex];		// The list of the first 100 customers, displayed in the modal
				this.sendModalDataToReportGraph();
			}

		},
		animation: {
			onComplete: function () {						// Displays the values above the bars once the graph finish the initial animation
				var chartInstance = this.chart,
				ctx = chartInstance.ctx;
				ctx.textAlign = 'center';
				ctx.textBaseline = 'bottom';
				this.data.datasets.forEach(function (dataset, i) {
					var meta = chartInstance.controller.getDatasetMeta(i);
					meta.data.forEach(function (bar, index) {
						var data = dataset.data[index];
						ctx.fillText(data, bar._model.x, bar._model.y - 5);
					});
				});
			}
		},
		responsive: true,
		scales: { 
			xAxes: [{}], 
			yAxes: [{
				display: true,
				minBarLength: 3,							// Minimum amount of pixel of bars (for values of 0)
				ticks: {
					//max: 100,
					min: 0
				}
			}],
		},
		plugins: {
			datalabels: {
				anchor: 'end',
				align: 'end'
			}
		}
	};

	public barChartLabels: Label[] = [];
	public barChartType: ChartType = 'bar';
	public barChartLegend = true;
	public barChartData: ChartDataSets[] = [];

	constructor(
		private ReportService: ReportService,
		config: NgbDatepickerConfig,
		calendar: NgbCalendar,
		private modalService: NgbModal
	) {}


	// Reset all important variables (after a logout)
	resetVariables () {
		this.waitingForMaxPeriodServerResponse = false;
		this.waitingForReportDataServerResponse = false;
		this.customers = [];
		this.fromDate = null;
		this.toDate = null;
		this.displayReportGraph = false;
		this.barChartData = [];
		this.reportingCustomerList = [];
	}

}
