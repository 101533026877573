import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { HttpHeaders } from '@angular/common/http';

import {ConfigService} from './config.service';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
	'charset': 'utf-8',
	'withCredentials' : "true",
	"Set-Cookie": "PHPSESSID=s042eh7jgc9gcrhi9fvgv743eq; path=/"
  })
};

export interface requestPerimeterResponse {
	customers: object[];
	hosts: object[];
}

export interface requestMaxPeriodResponse {
	start: number;
	end: number;
}

export interface requestUnitListResponse {
	data: object[];
}

export interface requestReportingDataResponse {
	report: object[];
}


export class Filters {

	hosts: number[];
	start: number;
	end: number;
	unit: string;
}


@Injectable({
	providedIn: 'root'
})
export class ReportService {

	serverUrl = this.configService.configData.serverUrl;
	
	private filters: Filters;

	constructor(
		private http: HttpClient,
		private configService:ConfigService
	) {}
	
	
	// Return the list of customers and hosts
	requestPerimeter (): any {
		const data = {
			"action" : "participation.reporting.perimeter",
			"parameters" : {}
		};
		var request = new XMLHttpRequest();
		request.open('POST', this.serverUrl, true);
		request.withCredentials = true;
		request.send(JSON.stringify(data));
		return request;
	}

	// Return the minimum and maximum date of the request
	requestMaxPeriod (codes: number[]): any {
		let jsonCodeArray = [];
		for (let code of codes){
			jsonCodeArray.push({"code": code});
		}

		const data = {
			"action" : "participation.reporting.periode.max",
			"parameters" : {
				"hosts" : jsonCodeArray
			}
		};
		var request = new XMLHttpRequest();
		request.open('POST', this.serverUrl, true);
		request.withCredentials = true;
		request.send(JSON.stringify(data));
		return request;
	}

	// Return the list of time units available for the display of the reporting data
	requestUnitList (): any {
		const data = {
			"action" : "participation.reporting.unit.all",
			"parameters" : {}
		};
		var request = new XMLHttpRequest();
		request.open('POST', this.serverUrl, true);
		request.withCredentials = true;
		request.send(JSON.stringify(data));
		return request;
	}

	requestReport(report: string, filters: Filters = null, format: string = null): any{
		if(filters !== null){
			this.filters = filters;
		}else{
			filters = this.filters;
		}
		
		var body = this.createBody(report, filters, format);
		
		if(format === "portable"){
			var pre = this.serverUrl + "?json=" + body;
			return window.location.href = pre;
		}
		
		
		var re = this.createXHRRequest();
		re.send(body);
		return re;
	}
	
	createXHRRequest(){
		var request = new XMLHttpRequest();
		request.open('POST', this.serverUrl, true);
		request.withCredentials = true;
		return request;
	}
	
	createBody(report: string, filters: Filters = null, format: string = null): any{
		let jsonHostArray = [];
		for (let hostNumber of filters.hosts){
			jsonHostArray.push({"code": hostNumber});
		}

		const data = {
			"action" : "participation.reporting.report",
			"parameters" : {
				"report" : report,
				"format" : format,
				"hosts" : jsonHostArray,
				"periode" : {
					"start" : filters.start,
					"end" : filters.end
				},
				"unit" : {"key": filters.unit}
			}
		};
		
		return JSON.stringify(data);
	}

	// Return the reporting data that are needed for the graph
	/*requestReportingData (report: string, hosts: number[], start: number, end: number, unit: string, format: string = null): any{
		let jsonHostArray = [];
		for (let hostNumber of hosts){
			jsonHostArray.push({"code": hostNumber});
		}

		const data = {
			"action" : "participation.reporting.report",
			"parameters" : {
				"report" : report,
				"format" : format,
				"hosts" : jsonHostArray,
				"periode" : {
					"start" : start,
					"end" : end
				},
				"unit" : {"key": unit}
			}
		};
		
		var request = new XMLHttpRequest();
		request.open('POST', this.serverUrl, true);
		request.withCredentials = true;
		request.send(JSON.stringify(data));
		return request;
	}*/

}
