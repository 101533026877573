import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class ConfigService {
	configData: any;

	constructor(private http: HttpClient) { }

	loadConfigData() {
		let _this = this;
		return this.http.get("assets/config.json").toPromise()
			.then(data => {_this.configData = data;});
	}

	getConfigData() {
		if (!this.configData) {
			throw Error("Config file not loaded!");
		}
		return this.configData;
	}
}
